<template>
  <div>
    <v-autocomplete
      class="ma-4"
      label="Process Type"
      placeholder="Filter by process type"
      hide-details
      dense
      @click.prevent.stop=""
      v-model="selectedProcessType"
      :items="availableProcessTypes"
      item-text="name"
      item-value="id"
      @change="filter"
    />
    <v-data-table
      class="inventory-details"
      :headers="headers"
      :items="flatData"
      item-key="name"
    >
      <template v-if="!isMobile" v-slot:header="{}">
        <thead>
          <tr>
            <th></th>
            <th :colspan="mode.inputs.expanded ? inputProducts.size + 1:1" :class="mode.inputs.expanded ? 'text-center':''">
              {{ $vuetify.lang.t("$vuetify.summary.inputs") }}
              <v-icon v-ripple @click="mode.inputs.expanded = !mode.inputs.expanded; filter()"> {{ mode.inputs.expanded ? 'mdi-minus-box-outline':'mdi-plus-box-outline' }} </v-icon>
            </th>
            <th :colspan="mode.outputs.expanded ? outputProducts.size + 1:1" :class="mode.outputs.expanded ? 'text-center':''">
              {{ $vuetify.lang.t("$vuetify.summary.outputs") }}
              <v-icon v-ripple @click="mode.outputs.expanded = !mode.outputs.expanded; filter()"> {{ mode.outputs.expanded ? 'mdi-minus-box-outline':'mdi-plus-box-outline' }} </v-icon>
            </th>
            <th :colspan="2" class="text-center">
              {{ $vuetify.lang.t("$vuetify.summary.summary") }}
            </th>
            <th></th>
          </tr>
        </thead>
      </template>

      <template v-slot:header.group="{}">
        <v-select
          :label="$vuetify.lang.t('$vuetify.summary.groupBy')"
          @change="filter"
          dense
          hide-details
          v-model="groupBy"
          :items="[
            { text: $vuetify.lang.t('$vuetify.summary.process'), value: 'process' },
            { text: $vuetify.lang.t('$vuetify.base.date'), value: 'date' },
          ]"
        />
      </template>

      <template v-if="groupBy == 'process'" v-slot:item.group="{ item }">
        <a
          target="_blank"
          :href="`https://prod.${domain}.com/factory/process/${item.group}`"
          >{{ item.code }}</a
        >
      </template>
      <template v-slot:item.inputTotal="{ item }">
        <div class="totalCell">{{ item.inputTotal | formatNumber }}</div>
      </template>

      <template v-slot:item.outputTotal="{ item }">
        <div class="totalCell">{{ item.outputTotal | formatNumber }}</div>
      </template>
      <template v-slot:item.delta="{ item }">
        <div class="totalCell">{{ item.delta | formatNumber }} ({{ item.outputTotal/item.inputTotal | formatPercent(2) }})</div>
      </template>
      <template v-slot:item.totalProcessTime="{ item }">
        <div class="totalCell">
          <span v-if="item.totalProcessTime">
            {{ (item.totalProcessTime / 60).toFixed(1) }} {{ $vuetify.lang.t("$vuetify.summary.hours") }}
          </span>
          <span v-else> N/A </span>
          <div class="text-caption"> {{item.firstInputTime | formatDateTime }} - {{ item.lastOutputTime | formatDateTime }} </div>
        </div>
      </template>

      <template v-slot:body.append="{}">
        <tr>
          <th>{{ $vuetify.lang.t("$vuetify.base.total") }}</th>
          <template v-if="mode.inputs.expanded">
            <th v-for="(input, index) in inputProducts" :key="index">
              {{ inProductTotals[`in-${input}`] | formatNumber }}
            </th>
          </template>
          <th>{{ totalInputs | formatNumber }}</th>

          <template v-if="mode.outputs.expanded">
            <th v-for="(output, index) in outputProducts" :key="index">
              {{ outProductTotals[`out-${output}`] | formatNumber }} ({{  outProductTotals[`out-${output}`]/totalInputs | formatPercent(2) }})
            </th>
          </template>

          <th>{{ totalOutputs | formatNumber }}</th>
          <th>{{ totalDelta | formatNumber }} ({{ totalOutputs/totalInputs | formatPercent(2) }}) </th>
          <th>
            <span v-if="totalProcessTime">
              {{  (totalProcessTime / 60).toFixed(1)  }} {{ $vuetify.lang.t("$vuetify.summary.hours") }}
            </span>
          </th>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  props: {
    value: {
      type: Array,
      required: false,
    },
    eventList: {
      type: Array,
      required: true,
      default: () => [],
    },
    domain: {
      type: String,
      required: false,
      default: "localhost",
    },
    date: {
      type: Date,
    },
  },
  computed: {
    ...mapGetters("inventoryCostAdj", ["costAdjustmentService"]),
    ...mapGetters("processes", ["processTypes"]),
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
  data: function () {
    return {
      headers: [],
      summary: null,
      filteredEventList: [],
      selectedProcessType: null,
      availableProcessTypes: [],
      flatData: [],
      inputProducts: [],
      outputProducts: [],
      inProductTotals: {},
      outProductTotals: {},
      groupBy: "process",
      groupKey2Code: {},
      totalInputs: 0,
      totalOutputs: 0,
      totalDelta: 0,
      totalProcessTime: 0,
      mode: {
        inputs: {expanded: false},
        outputs: {expanded: false},
      }
    };
  },
  mounted() {
    const availableProcessIds = new Set();
    this.eventList.forEach((e) => {
      availableProcessIds.add(e.processTypeId);
    });
    this.availableProcessTypes = this.processTypes.filter((p) =>
      availableProcessIds.has(p.id)
    );
    this.selectedProcessType = this.availableProcessTypes[0].id;
    this.filter();
  },
  methods: {
    ...mapActions("processes", ["loadProcessTypes"]),
    getCostAdjustments(inventoryId, balanceDate) {
      return this.costAdjustmentService.getCostAdjustmentDetails(
        inventoryId,
        balanceDate
      );
    },
    filter() {
      this.filteredEventList = this.eventList;
      this.headers = [{ text: "Group", value: "group" }];
      this.filteredEventList = this.eventList.filter(
        (e) => e.processTypeId == this.selectedProcessType
      );
      this.summarize();
    },
    summarize() {
      this.groupKey2Code = {};
      this.inProductTotals = {};
      this.outProductTotals = {};

      this.summary = this.filteredEventList.reduce(
        (group, row) => {
          let groupKey = null;
          if (this.groupBy == "process") {
            groupKey = row.processId;
          } else {
            //group by date
            groupKey = row.time.substring(0, 10);
          }

          if (!group[groupKey]) {
            group[groupKey] = {
              inputs: {},
              outputs: {},
              inputTotal: 0,
              outputTotal: 0,
            };
            this.groupKey2Code[groupKey] = row.processCode;
          }

          if (row.type == "inputs") {
            const inputs = group[groupKey].inputs;
            const key = `in-${row.product}`;
            if (inputs[key]) {
              inputs[key].inventories.push(row);
              inputs[key].quantity += Math.abs(row.quantity);
              inputs[key].processTimes.push(row.time);
            } else {
              inputs[key] = {
                inventories: [row],
                quantity: Math.abs(row.quantity),
                processTimes: [row.time],
              };
            }

            group.inputProducts.add(row.product);

            if (this.inProductTotals[`in-${row.product}`]) {
              this.inProductTotals[`in-${row.product}`] += Math.abs(
                row.quantity
              );
            } else {
              this.inProductTotals[`in-${row.product}`] = Math.abs(
                row.quantity
              );
            }
          }
          if (row.type == "outputs") {
            const outputs = group[groupKey].outputs;
            const key = `out-${row.product}`;
            if (outputs[key]) {
              outputs[key].inventories.push(row);
              outputs[key].quantity += row.quantity;
              outputs[key].processTimes.push(row.time);
            } else {
              outputs[key] = {
                inventories: [row],
                quantity: row.quantity,
                processTimes: [row.time],
              };
            }

            group.outputProducts.add(row.product);

            if (this.outProductTotals[`out-${row.product}`]) {
              this.outProductTotals[`out-${row.product}`] += row.quantity;
            } else {
              this.outProductTotals[`out-${row.product}`] = row.quantity;
            }
          }

          return group;
        },
        { inputProducts: new Set(), outputProducts: new Set() }
      );

      // console.log(`grouped by ${this.groupBy}: `, this.summary);

      this.calculateProcessTimeInHours(this.summary);
      this.flatten(this.summary);
    },

    flatten(summary) {
      const data = [];
      let groupKeys = Object.keys(summary);
      groupKeys = groupKeys.filter(
        (k) => !["outputProducts", "inputProducts"].includes(k)
      );
      const inputProducts = summary.inputProducts;
      const outputProducts = summary.outputProducts;

      if(this.mode.inputs.expanded){
        inputProducts.forEach((product) => {
          this.headers.push({ text: product, value: `in-${product}` });
        });
      }

      this.headers.push({ text: this.$vuetify.lang.t('$vuetify.summary.inputTotal'), value: "inputTotal" });

      if(this.mode.outputs.expanded){
        outputProducts.forEach((product) => {
          this.headers.push({ text: product, value: `out-${product}` });
        });
      }
      this.headers.push({ text: this.$vuetify.lang.t('$vuetify.summary.outputTotal'), value: "outputTotal" });
      this.headers.push({ text: this.$vuetify.lang.t('$vuetify.summary.delta'), value: "delta" });
      this.headers.push({ text: this.$vuetify.lang.t('$vuetify.summary.processTime'), value: "totalProcessTime" });

      let totalInputs = 0;
      let totalOutputs = 0;
      let totalDelta = 0;
      let totalProcessTime = 0;

      groupKeys.forEach((groupKey) => {
        const row = { group: groupKey, code: this.groupKey2Code[groupKey] };
        let inputQuantity = 0;
        let outputQuantity = 0;

        inputProducts.forEach((inputProduct) => {
          const key = `in-${inputProduct}`;
          const productVal = summary[groupKey].inputs[key];
          const quantity = productVal ? productVal.quantity : null;
          inputQuantity += quantity;
          row[key] = this.$options.filters.formatNumber(quantity);
        });

        outputProducts.forEach((outputProduct) => {
          const key = `out-${outputProduct}`;
          const productVal = summary[groupKey].outputs[key];
          const quantity = productVal ? productVal.quantity : null;
          outputQuantity += quantity;
          // row[key] = this.$options.filters.formatNumber(quantity);
          row[key] = `${this.$options.filters.formatNumber(quantity)} (${this.$options.filters.formatPercent(quantity / inputQuantity, 2)})`;
        });

        row["inputTotal"] = inputQuantity;
        row["outputTotal"] = outputQuantity;
        row["delta"] = outputQuantity - inputQuantity;
        row["totalProcessTime"] = summary[groupKey].totalProcessTime;
        row["firstInputTime"] = summary[groupKey].firstInputTime;
        row["lastOutputTime"] = summary[groupKey].lastOutputTime;

        //update total row
        totalInputs += inputQuantity;
        totalOutputs += outputQuantity;
        totalDelta += outputQuantity - inputQuantity;
        totalProcessTime += summary[groupKey].totalProcessTime ? summary[groupKey].totalProcessTime:0;

        data.push(row);
      });


      this.flatData = data;
      this.inputProducts = inputProducts;
      this.outputProducts = outputProducts;

      this.totalInputs = totalInputs;
      this.totalOutputs = totalOutputs;
      this.totalDelta = totalDelta;
      this.totalProcessTime = totalProcessTime;

      this.$emit("input", this.flatData);
    },
    calculateProcessTimeInHours(summary) {
      //return the total length of time (hours); last output time - first input time
      for (const pId in summary) {
        const processSummary = summary[pId];
        let firstInputTime = null;
        let lastOutputTime = null;

        for (const inputProduct in processSummary.inputs) {
          const producDetails = processSummary.inputs[inputProduct];
          const productInputTime = producDetails.processTimes.sort()[0];
          if (firstInputTime == null || firstInputTime > productInputTime) {
            firstInputTime = productInputTime;
          }
        }

        for (const outputProduct in processSummary.outputs) {
          const productDetials = processSummary.outputs[outputProduct];
          const productOutputTime = productDetials.processTimes
            .sort()
            .slice(-1)[0];
          if (lastOutputTime == null || lastOutputTime < productOutputTime) {
            lastOutputTime = productOutputTime;
          }
        }

        processSummary.firstInputTime = firstInputTime;
        processSummary.lastOutputTime = lastOutputTime;
        if (processSummary.firstInputTime && processSummary.lastOutputTime) {
          const duration = moment.duration(
            moment(processSummary.lastOutputTime).diff(
              moment(processSummary.firstInputTime)
            )
          );
          processSummary.totalProcessTime = duration.asMinutes();
        }
      }
    },
  },
};
</script>

<style scoped>
.totalCell {
  font-weight: bold;
}
</style>
<style>
.inventory-details thead.v-data-table-header > tr > th > div {
  display: inline-block;
  width: 80%;
}

.inventory-details thead.v-data-table-header > tr > th:first-child {
  min-width: 150px;
}

.inventory-details thead.v-data-table-header > tr > th {
  white-space: nowrap;
}
</style>
